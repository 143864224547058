<template>
  <v-subheader class="subtitle-2 list-subheader primary--text">{{
    title
  }}</v-subheader>
</template>

<script>
export default {
  name: 'ListSubheader',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="sass" scoped>
.list-subheader
  min-height: 48px !important
  height: 48px !important
  line-height: 48px !important
</style>
